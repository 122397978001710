.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.data-stats-slider-outer .swiper-button-next:after,
.data-stats-slider-outer .swiper-button-prev:after,
.data-stats-slider-outer .swiper-button-next,
.data-stats-slider-outer .swiper-button-prev{
  top: 50%;
  height: 2.875rem;
  width: 2.875rem;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1 !important;
  color: rgb(28 36 52 / var(--tw-text-opacity)) !important;
  --tw-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  --tw-shadow-colored: 0px 8px 13px -3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .data-stats-slider-outer .swiper-button-next),:is(.dark 
.data-stats-slider-outer .swiper-button-prev){
  --tw-border-opacity: 1;
  border-color: rgb(46 58 71 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(49 61 74 / var(--tw-bg-opacity));
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
.data-stats-slider-outer .swiper-button-next.swiper-button-disabled,
.data-stats-slider-outer .swiper-button-prev.swiper-button-disabled{
  display: none;
}
.data-stats-slider-outer .swiper-button-prev{
  left: -23px;
}
.data-stats-slider-outer .swiper-button-next{
  right: -23px;
}